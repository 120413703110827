<template>
  <div class="statusReportContainerFlexColumn">
    <div class="statusReportContainerFlexColumn__top">
      <div class="title">状态时长统计报表</div>
      <el-divider />
    </div>
    <dc-search-panel :params="queryParams" style="border: none; background-color: white; margin-bottom: 0" @search="doSearch" @reset="reset">
      <dc-form-item label="统计时间(起)">
        <el-date-picker v-model="queryParams.startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </dc-form-item>
      <dc-form-item label="统计时间(止)">
        <el-date-picker v-model="queryParams.endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </dc-form-item>
      <dc-input field="suser" label="客服名称"></dc-input>
      <div class="dc-form-item">
        <div class="dc-form-item__label" style="width: 100px">状态</div>
        <div class="dc-form-item__body">
          <el-select v-model="queryParams.pstatus">
            <el-option v-for="item in selected" :key="item.pstatus" :value="item.pstatus" :label="item.name"> </el-option>
          </el-select>
        </div>
      </div>
    </dc-search-panel>

    <div class="buttons">
      <el-button type="primary" @click="getNowDay">当天</el-button>
      <el-button type="primary" @click="getMonth">当月</el-button>
      <el-button type="primary" @click="doSearch">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
      <el-button type="primary" @click="exportExcel">导出Excel</el-button>
    </div>
    <div class="statusReportContainerFlexColumn__body">
      <dc-datatable ref="list" :url="apiUrl" :params="queryParams" :show-summary="true" @loaded="loaded">
        <el-table-column prop="s_user" width="120" label="客服名称" align="center"></el-table-column>
        <el-table-column prop="c_phone" width="120" label="客服账号" align="center"></el-table-column>
        <el-table-column prop="n_count" width="80" label="次数" align="center"></el-table-column>
        <el-table-column prop="n_count_percent" width="80" label="次数占比" align="center"></el-table-column>
        <el-table-column prop="n_time" width="120" label="时长" align="center">
          <template #default="scope">
            {{ scope.row.n_time | getTimeFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="n_time_percent" width="80" label="时长占比" align="center"> </el-table-column>
        <el-table-column prop="p_status" label="状态" align="center">
          <template #default="scope">
            {{ scope.row.p_status | getStatusText }}
          </template>
        </el-table-column>
        <el-table-column prop="p_status" label="操作" align="center" width="120">
          <template #default="scope">
            <el-button type="primary" size="mini" @click="detail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </dc-datatable>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import { Message } from "element-ui";
import moment from "moment";
import axios from "axios";
import { tansParams } from "@/api/device/device";
import { getToken } from "@/utils/auth";

export default {
  name: "StatusReport",
  filters: {
    dateFilter(dateString) {
      // 示例时间字符串

      // 创建一个 Date 对象
      const date = new Date(dateString);

      // 获取各部分
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始计数，所以要加1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      // 格式化为更易读的格式
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getTimeFormat(val) {
      let h = parseInt(val / 3600);
      h = h > 9 ? `${h}` : `0${h}`;
      let m = parseInt((val - 3600 * h) / 60);
      m = m > 9 ? `${m}` : `0${m}`;
      let s = val - 3600 * h - 60 * m;
      s = s > 9 ? `${s}` : `0${s}`;
      return `${h}:${m}:${s}`;
    },
    getStatusText(status) {
      if (status === "-1") return "退出登录";
      else if (status === "0") return "登录CTI成功";
      else if (status === "2") return "座席就绪(示闲)";
      else if (status === "3") return "座席忙碌(示忙)";
      else if (status === "4") return "座席外拨";
      else if (status === "5") return "座席振铃";
      else if (status === "7") return "座席挂机处于空闲状态";
      else if (status === "8") return "座席挂机处于后处理状态(整理态)";
      else if (status === "10") return "座席接通呼入电话";
      else if (status === "11") return "座席接通呼出电话";
      else if (status === "12") return "座席正在咨询中";
      else if (status === "13") return "座席在会议中";
      else if (status === "14") return "用户处于保持中";
      else if (status === "16") return "座席正在监听中";
      else if (status === "17") return "座席正在辅助中!";
      else if (status === "18") return "座席正在强插中";
      else if (status === "20") return "座席外呼,对方正在振铃";
      else if (status === "21") return "座席咨询,对方正在振铃";
      else if (status === "22") return "桥接中，座席处于保持状态";
      else if (status === "23") return "座席外呼中!";
      else if (status === "24") return "座席内呼中!";
      else if (status === "25") return "咨询失败，用户保持状态";
      else if (status === "26") return "外呼后座席振铃前状态";
      else if (status === "27") return "内部后座席振铃前状态";
    },
  },
  props: {},
  data() {
    return {
      apiUrl: `${this.baseUrls.callDataCount}/statusReport`,
      params: {},
      queryParams: {},
      selected: [
        // { pstatus: "-1", name: "没有初始化" },
        { pstatus: "", name: "全部" },
        { pstatus: "-1", name: "退出登录" },
        { pstatus: "0", name: "登录CTI成功" },
        { pstatus: "2", name: "座席就绪(示闲)" },
        { pstatus: "3", name: "座席忙碌(示忙)" },
        { pstatus: "4", name: "座席外拨" },
        { pstatus: "5", name: "座席振铃" },
        { pstatus: "7", name: "座席挂机处于空闲状态" },
        { pstatus: "8", name: "座席挂机处于后处理状态(整理态)" },
        { pstatus: "10", name: "座席接通呼入电话" },
        { pstatus: "11", name: "座席接通呼出电话" },
        { pstatus: "12", name: "座席正在咨询中" },
        { pstatus: "13", name: "座席在会议中" },
        { pstatus: "14", name: "用户处于保持中" },
        { pstatus: "16", name: "座席正在监听中" },
        { pstatus: "17", name: "座席正在辅助中!" },
        { pstatus: "18", name: "座席正在强插中" },
        { pstatus: "20", name: "座席外呼,对方正在振铃" },
        { pstatus: "21", name: "座席咨询,对方正在振铃" },
        { pstatus: "22", name: "桥接中，座席处于保持状态" },
        { pstatus: "23", name: "座席外呼中!" },
        { pstatus: "24", name: "座席内呼中!" },
        { pstatus: "25", name: "咨询失败，用户保持状态" },
        { pstatus: "26", name: "外呼后座席振铃前状态" },
        { pstatus: "27", name: "内部后座席振铃前状态" },
      ],
      n_count_total: 0,
      n_time_total: 0,
    };
  },
  created() {
    this.resetPara();
  },
  mounted() {},
  methods: {
    doSearch() {
      let startDate = moment(this.queryParams.startDate, "YYYY-MM-DD");
      let endDate = moment(this.queryParams.endDate, "YYYY-MM-DD");

      if (startDate.isAfter(endDate)) {
        this.$message.error("统计时间的开始时间不能超过结束时间！");
        return;
      }

      startDate = startDate.add(6, "months");

      if (endDate.isAfter(startDate)) {
        this.$message.error("统计时间的范围不能超过6个月！");
        return;
      }
      this.$refs.list.load();
    },
    getMonth() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().startOf("month").format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().endOf("month").format("YYYY-MM-DD");
      this.doSearch();
    },
    getNowDay() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
    },
    resetPara() {
      this.queryParams = {
        suser: "",
        pstatus: "",
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      };
    },
    loaded(data, cb) {
      let _this = this;
      this.$nextTick(async () => {
        let url = `${_this.baseUrls.callDataCount}/statusReportTotal`;
        let params = _this.queryParams;
        _this.topsAjax.get(url, params).then(async (resp) => {
          _this.n_time_total = resp.n_time;
          _this.n_count_total = resp.n_count;
          await this.showSummariesPosition();
          data.forEach((p) => {
            p.n_time_percent = Math.round((p.n_time / _this.n_time_total) * 10000) / 100 + "%";
            p.n_count_percent = Math.round((p.n_count / _this.n_count_total) * 10000) / 100 + "%";
          });
          cb(data);
        });
      });
    },
    // 合计行设置
    showSummariesPosition() {
      // 合计行显示在表头
      let table = document.querySelector(".el-table");
      let footer = document.querySelector(".el-table__footer-wrapper");
      let body = document.querySelector(".el-table__body-wrapper");
      table.removeChild(footer);
      table.insertBefore(footer, body);
      footer.style = "background-color: white";
      // 在合计行的最后一列添加按钮
      table.querySelectorAll("td")[0];
      let html = table.querySelectorAll("td")[0].querySelector(".cell");
      html.innerHTML = "合计";
      table.querySelectorAll("td")[2].querySelector(".cell").innerHTML = this.n_count_total;
      const formattedTime = this.n_time_total != undefined ? this.$options.filters.getTimeFormat(this.n_time_total) : "00:00:00";
      table.querySelectorAll("td")[4].querySelector(".cell").innerHTML = formattedTime;
    },
    detail(row) {
      this.$router.push({ path: "/call/statusReportDetail", query: { userId: row.r_user, status: row.p_status, startDate: this.queryParams.startDate, endDate: this.queryParams.endDate } });
    },
    exportExcel() {
      let url = `${this.baseUrls.callDataCount}/statusReportExcel`;
      let params = this.queryParams;
      axios
        .post(url, params, {
          transformRequest: [
            (params) => {
              return tansParams(params);
            },
          ],
          headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: getToken() },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "状态时长统计.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style lang="scss">
.statusReportContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;
  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }
  }

  .buttons {
    text-align: right;
    clear: both;
    padding-right: 10px;
    background-color: white;
  }

  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }

  .el-table {
    display: flex;
    flex-direction: column;
  }
  .el-table__body-wrapper {
    order: 1;
  }
  .el-table__fixed-body-wrapper {
    top: 96px !important;
  }
  .el-table__fixed-footer-wrapper {
    z-index: 0;
  }
}
</style>
