var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "statusReportContainerFlexColumn" },
    [
      _c(
        "div",
        { staticClass: "statusReportContainerFlexColumn__top" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("状态时长统计报表")]),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "dc-search-panel",
        {
          staticStyle: {
            border: "none",
            "background-color": "white",
            "margin-bottom": "0",
          },
          attrs: { params: _vm.queryParams },
          on: { search: _vm.doSearch, reset: _vm.reset },
        },
        [
          _c(
            "dc-form-item",
            { attrs: { label: "统计时间(起)" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.queryParams.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "startDate", $$v)
                  },
                  expression: "queryParams.startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "dc-form-item",
            { attrs: { label: "统计时间(止)" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.queryParams.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "endDate", $$v)
                  },
                  expression: "queryParams.endDate",
                },
              }),
            ],
            1
          ),
          _c("dc-input", { attrs: { field: "suser", label: "客服名称" } }),
          _c("div", { staticClass: "dc-form-item" }, [
            _c(
              "div",
              {
                staticClass: "dc-form-item__label",
                staticStyle: { width: "100px" },
              },
              [_vm._v("状态")]
            ),
            _c(
              "div",
              { staticClass: "dc-form-item__body" },
              [
                _c(
                  "el-select",
                  {
                    model: {
                      value: _vm.queryParams.pstatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "pstatus", $$v)
                      },
                      expression: "queryParams.pstatus",
                    },
                  },
                  _vm._l(_vm.selected, function (item) {
                    return _c("el-option", {
                      key: item.pstatus,
                      attrs: { value: item.pstatus, label: item.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getNowDay } },
            [_vm._v("当天")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getMonth } },
            [_vm._v("当月")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.doSearch } },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.reset } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.exportExcel } },
            [_vm._v("导出Excel")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "statusReportContainerFlexColumn__body" },
        [
          _c(
            "dc-datatable",
            {
              ref: "list",
              attrs: {
                url: _vm.apiUrl,
                params: _vm.queryParams,
                "show-summary": true,
              },
              on: { loaded: _vm.loaded },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "s_user",
                  width: "120",
                  label: "客服名称",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "c_phone",
                  width: "120",
                  label: "客服账号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "n_count",
                  width: "80",
                  label: "次数",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "n_count_percent",
                  width: "80",
                  label: "次数占比",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "n_time",
                  width: "120",
                  label: "时长",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("getTimeFormat")(scope.row.n_time)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "n_time_percent",
                  width: "80",
                  label: "时长占比",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "p_status", label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStatusText")(scope.row.p_status)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "p_status",
                  label: "操作",
                  align: "center",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.detail(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }